import { MAIN_COLOR, INTER_FONT, ROBOTO_FONT } from "@/constants/style"

export default (app) => {
    app.directive('color', (el, bind) => {
        if (bind.value) {
            el.style.color = bind.value
            return;
        }
        el.style.color = MAIN_COLOR
    })
    app.directive('background', (el, bind) => {
        if (bind.value) {
            el.style.backgroundColor = bind.value
            return;
        }
        el.style.backgroundColor = MAIN_COLOR
    })
    app.directive('inter', (el) => {
        el.style.fontFamily = INTER_FONT
    })
    app.directive('roboto', (el) => {
        el.style.fontFamily = ROBOTO_FONT
    })
}