import store from "@/stores"
const addPrefixImage = (src) => {
    src ||= require('@/assets/images/default.jpg')
    if (src && (src.startsWith('http') || src.startsWith('/img'))) {
        return src
    }
    return process.env.VUE_APP_PREVIEW + src
}

export default (app) => {
    app.config.globalProperties.$addPrefixImage = addPrefixImage
    app.config.globalProperties.$slug = store.state.common.ward.slug
}