import api from '@/api'


export default {
    actions: {
        async ListArticleTypes(_, options) {
            const response = await this.$axios({
                method: 'GET',
                url: api.ListArticleTypes,
                params: options
            })
            return response.data;
        },
    }
}