import api from "@/api";

export default {
	actions: {
		async GetContact(_, options) {
			const response = await this.$axios({
				method: "GET",
				url: api.params("GetContact",options)
			});
			return response.data;
		},
		async CreateContact(_, data) {
			const response = await this.$axios({
				method: "POST",
				url: api.CreateContact,
				data: data
			});
			return response.data;
		},
	},
};
