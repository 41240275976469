import api from '@/api'
import axios from 'axios'
export default {
    actions: {
        async ListknowledgeLibrary(_, options = {}) {
            const { type, category } = options
            options = {
                ...options,
                'category-id': options['category-id'] ?? category ?? 1,
            }
            let response
            if (type == 2) {
                response = await this.$axios({
                    method: 'GET',
                    url: api.ListknowledgeLibraryVideos,
                    params: options,
                })
            } else {
                response = await this.$axios({
                    method: 'GET',
                    url: api.ListknowledgeLibraryArticles,
                    params: options,
                })
            }
            return response.data
        },
        async ListCategoryknowledgeLibrary() {
            let url = process.env.VUE_APP_CMS + api.ListCategoryknowledgeLibrary
            url = url.replace(process.env.VUE_APP_HOST, '')
            const response = await axios({
                method: 'GET',
                url: url,
            })
            return response.data
        },
        async GetknowledgeLibraryArticle(_, id) {
            if(id === undefined) return;
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetknowledgeLibraryArticle', { id: id }),
            })
            return response.data
        },
        async GetknowledgeLibraryVideo(_, id) {
            if(id === undefined) return;
            const response = await this.$axios({
                method: 'GET',
                url: api.params('GetknowledgeLibraryVideo', { id: id }),
            })
            return response.data
        },
    },
}
