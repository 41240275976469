import filters from "./filters";
import helpers from "./helpers";
import directives from "./directives";

export default {
    install: (app) => {
        helpers(app);
        filters(app);
        directives(app);
    }
}