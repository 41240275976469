import { createStore } from 'vuex'
import axios from '@/core/axios';

import modules from './modules/_bootstrap';
const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})
store.$axios = axios
export default store;
